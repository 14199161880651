import React, { Component } from "react"
import PropTypes from "prop-types"

import Image from "../../image"

import { Styles } from "./style"

class PhotosList extends Component {
  printThreePhotos(photos) {
    return (
      <div className="three-pic-wrapper">
        {photos.map(({ imgName, alt }, idx) => (
          <div className="img-wrapper" key={idx}>
            <Image imgName={imgName} alt={alt} />
          </div>
        ))}
      </div>
    )
  }

  render() {
    const { photos, type } = this.props

    let allPhoto = []
    for (let i = 0; i < photos.length; i += 3) {
      allPhoto.push(this.printThreePhotos(photos.slice(i, i + 3)))
    }

    let bePartOf = ""
    if (type === "academy") {
      bePartOf = "THE ACADEMICIANS"
    } else if (type === "competition") {
      bePartOf = "THE CHAMPIONS"
    }

    return (
      <Styles>
        <div>BE PART OF</div>
        <h1 className="linear-colored">{bePartOf}</h1>
        <div>{allPhoto}</div>
      </Styles>
    )
  }
}

PhotosList.propTypes = {
  type: PropTypes.string,
  photos: PropTypes.array,
}

export default PhotosList

import styled from "styled-components"

export const Styles = styled.div`
  padding-top: 150px;
  padding-bottom: 20px;
  text-align: center;

  h1 {
    margin-top: 5px;
  }

  .three-pic-wrapper:nth-of-type(1) {
    display: grid;
    grid-template-columns: 68.1% 31.9%;
  }

  .three-pic-wrapper:nth-of-type(2) {
    display: grid;
    grid-template-columns: 33.3% 66.7%;
  }

  .three-pic-wrapper:nth-of-type(3) {
    display: grid;
    grid-template-columns: 66.7% 33.3%;
  }

  .three-pic-wrapper:nth-of-type(odd) .img-wrapper:nth-of-type(1) {
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .three-pic-wrapper:nth-of-type(even) .img-wrapper:nth-of-type(2) {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
  }

  @media screen and (max-width: 992px) {
    padding-top: 80px;

    .three-pic-wrapper:nth-of-type(odd),
    .three-pic-wrapper:nth-of-type(even) {
      grid-template-columns: auto auto;
    }

    .three-pic-wrapper:nth-of-type(odd) .img-wrapper:nth-of-type(1),
    .three-pic-wrapper:nth-of-type(even) .img-wrapper:nth-of-type(1) {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .three-pic-wrapper:nth-of-type(even) .img-wrapper:nth-of-type(2) {
      grid-row-start: initial;
      grid-row-end: initial;
      grid-column-start: initial;
      grid-column-end: initial;
    }
  }
`
